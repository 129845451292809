.board-map {
    display: inline-block;
    background-color: darkseagreen;
    width: 200rem;
    height: 100rem;
    z-index: -1;
    position: absolute;
    top: 0%;
    left: 0%;
    padding: 5rem;
  }
.athens-city {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    padding-top: 0.1rem;
    padding-left: 0.1rem;
  }
  .troy-city {
    position: absolute;
    left: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    padding-bottom: 0.1rem;
    padding-left: 0.1rem;
  }
  .sparta-city {
    position: absolute;
    right: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    padding-top: 0.1rem;
    padding-right: 0.1rem;
  }
  .thebes-city {
    position: absolute;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    padding-bottom: 0.1rem;
    padding-right: 0.1rem;
  }
  
  .farm {
    position: absolute;
    width: 35%;
    background-color: transparent;
    border: transparent;
    z-index: 4;
  }
  .mine {
    position: absolute;
    width: 25%;
    background-color: transparent;
    border: transparent;
    z-index: 4;
  }
  .athens-city-farm {
    left: 25%;
  }
  .athens-city-mine {
    top: 0%;
  }
  .sparta-city-farm {
    right: 25%;
  }
  .sparta-city-mine {
    right: 0%;
  }
  .troy-city-farm {
    bottom: 0%;
    left: 25%;
  }
  .troy-city-mine {
    bottom: -4%;
  }
  .thebes-city-farm {
    bottom: 0%;
    right: 25%;
  }
  .thebes-city-mine {
    bottom: -4%;
    right: 0%;
  }
  
  .card-slot {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 7rem;
    border: 2px solid rgba(41, 41, 41, 0.1);
    background-color: rgba(41, 41, 41, 0.068)
  }
  
  .athens-city-container {
    font-size: x-large;
    color: dodgerblue;
    width: 26rem;
    height: 26rem;
    top: 5%;
    left: 5%;
    position: absolute;
    border: black solid 1px;
  }
  .troy-city-container {
    font-size: x-large;
    color: darkgreen;
    width: 26rem;
    height: 26rem;
    bottom: 5%;
    left: 5%;
    position: absolute;
    border: black solid 1px;
  }
  .thebes-city-container {
    font-size: x-large;
    color: goldenrod;
    width: 26rem;
    height: 26rem;
    bottom: 5%;
    right: 5%;
    position: absolute;
    border: black solid 1px;
  }
  .sparta-city-container {
    font-size: x-large;
    color: darkred;
    width: 26rem;
    height: 26rem;
    top: 5%;
    right: 5%;
    position: absolute;
    border: black solid 1px;
  }
  
  .city-name {
    left: 30%;
    bottom: 35%;
    text-align: center;
    position: absolute;
  }
  
  .halfarmy {
    display: flex;
    position: relative;
  }
  
  .athens-city-halfarmy-l {
    top: 72%
  }
  .athens-city-halfarmy-r {
    top: 8.5%;
    left: 35.9%;
    transform: rotate(90deg);
  }
  .troy-city-halfarmy-l {
    top:0%;
  }
  .troy-city-halfarmy-r {
    left: 35.9%;
    top: 36%;
    transform: rotate(90deg);
  }
  .sparta-city-halfarmy-l {
    top: 8.3%;
    right: 36.3%;
    transform: rotate(270deg);
  }
  .sparta-city-halfarmy-r {
    top: 44%;
    left: 27.6%;
  }
  .thebes-city-halfarmy-l {
    top: 36%;
    right: 36%;
    transform: rotate(270deg);
  }
  .thebes-city-halfarmy-r {
    bottom: 28%;
    left: 27.6%;
  }
  .halfsquare {
    width:3rem;
    height:3.5rem;
  }
  .empty {
    width: 6.2rem;
    height: 7rem;
  }
  .battlefield-t1 {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 2rem
  }
  .battlefield-t2 {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 2rem;
  }
  .battlefield-container-l{
    position: absolute;
    width: 38rem;
    top: 43%;
    left: 2.2%;
  }
  .battlefield-container-r{
    position: absolute;
    width: 38rem;
    top: 43%;
    right: 2.2%;
  }
  .battlefield-container-t{
    position: absolute;
    width: 25rem;
    top: 2%;
    right: 45%;
    transform: rotate(90deg);
  }
  .battlefield-container-b{
    position: absolute;
    width: 25rem;
    bottom: 2%;
    right: 45%;
    transform: rotate(90deg);
  }
  .battlefield2-t1 {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 2rem;
    transform: rotate(180deg);
  }
  .battlefield2-t2 {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 2rem;
  }
  .city-battlefield-s {
    display: flex;
    position: relative;
    margin-bottom: 2rem
  }
  .city-battlefield2-d {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 2rem;
  }
  .city-battlefield-container-as {
    position: absolute;
    width: 38rem;
    top: 33%;
    left: 2.2%;
  }
  .city-battlefield-container-ss {
    position: absolute;
    width: 38rem;
    top: 33%;
    right: 2.2%;
  }
  .city-battlefield-container-ts {
    position: absolute;
    width: 38rem;
    top: 61%;
    left: 2.2%;
  }
  .city-battlefield-container-ths {
    position: absolute;
    width: 38rem;
    top: 61%;
    right: 2.2%;
  }
  .city-battlefield2-container-ad {
    position: absolute;
    width: 25rem;
    top: 9.5%;
    left: 17%;
    transform: rotate(270deg);
  }
  .city-battlefield2-container-sd {
    position: absolute;
    width: 25rem;
    top: 9.5%;
    right: 17.5%;
    transform: rotate(90deg);
  }
  .city-battlefield2-container-td {
    position: absolute;
    width: 25rem;
    bottom: 9.5%;
    left: 17%;
    transform: rotate(270deg);
  }
  .city-battlefield2-container-thd {
    position: absolute;
    width: 25rem;
    bottom: 9.5%;
    right: 17.5%;
    transform: rotate(90deg);
  }
  .delphi-container {
    position: absolute;
    left: 31%;
    top: 32%;
    height: 35%;
    width: 35%;
    border: black solid 2px;
  }
  .delphi-battlefield {
    display: flex;
    position: relative;
    margin-top: 2rem;
  }
  .delphi-battlefield-container-t {
    position: relative;
    width: 38rem;
    bottom: 5%;
    left: 25.7%;
  }
  .delphi-battlefield-container-b {
    position: relative;
    width: 38rem;
    top: 52%;
    left: 25.7%;
  }
  .delphi-battlefield-container-l {
    position: relative;
    width: 38rem;
    bottom: 12%;
    right: 21%;
    transform: rotate(90deg);
  }
  .delphi-battlefield-container-r {
    position: relative;
    width: 38rem;
    bottom: 37.2%;
    left: 69.5%;
    transform: rotate(-90deg);
  }
  .delphi-temple {
    position: absolute;
    width: 52%;
    height: 30%;
    border: black solid 2px;
    left: 25%;
    bottom: 33%;
  }
  .delphi-hero-button {
    position: relative;
    font-size: x-large;
    background-color: cadetblue;
    width: 30%;
    height: 25%;
    transform: rotate(90deg);
    top: 38%;
    right: 11%;
  }
  .delphi-oracle-button {
    position: relative;
    font-size: x-large;
    background-color: cadetblue;
    width: 30%;
    height: 35%;
    transform: rotate(270deg);
    bottom: 27%;
    left: 80%;
  }
  .delphi-temple-center {
    position: relative;
    font-size: xx-large;
    width: 30%;
    height: 35%;
    top: 20%;
    left: 40%;
  }

  .can-place {
    background-color: rgba(127, 255, 212, 0.459);
  }
  .citizen-place {
    border: rgba(255, 255, 255, 0.603) solid 0.5rem;
  }